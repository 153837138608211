import React, { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5exporting from '@amcharts/amcharts5/plugins/exporting';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
// import dataParserKeyValue from '../../helpers/dataParserKeyValue';
import displayDate from "../../helpers/displayDate";
import {MAX_PIE_SLICES} from '../../constants';
import { v4 as uuid } from 'uuid';

const dataParserKeyValue = (resultSet, chartType) => {
  const { granularity } = resultSet.query().timeDimensions[0] || '';
  const keyValue = resultSet.seriesNames()[0].key;
  
  const pivot = resultSet.chartPivot({ y: resultSet.pivotQuery().measures });
  const datePattern = /\d{4}-\d{2}-\d{2}/i;

  const typeSizeCheck = {
    pie: MAX_PIE_SLICES,
  }

  const data = pivot.slice(0, typeSizeCheck[chartType] || null).map((element) => {
    const keys = element.xValues.map((el) => {
  
      const isDate = el && (new Date(el) !== "Invalid Date") && !isNaN(new Date(el)) && el.match(datePattern);
      
      if (isDate) {
        return displayDate(el, granularity);
      } else {
        return el || "Não definido";
      }
    })

    let yValues = [];

    if(granularity) {
      yValues = resultSet.backwardCompatibleData[0].map(x => x[keyValue])[0];
    } else {
      yValues = element[resultSet.pivotQuery().measures.filter((x) => x.endsWith('count'))[0]];
    }
    
    return {
      key: keys,
      value: yValues
    }
  });
  
  return data;
}


function PieChartAM({ resultSet, chartSize }) {
  const id = uuid();

  useLayoutEffect(() => {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new(id);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);


    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    let chart = root.container.children.push(am5percent.PieChart.new(root, {
      layout: root.verticalLayout,
    }));

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    let series = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: "value",
      categoryField: "key"
    }));

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    let data = dataParserKeyValue(resultSet, 'pie');
    series.data.setAll(data);

    // Create legend
    // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      marginTop: 15,
      marginBottom: 15
    }));
    legend.labels.template.setAll({
      maxWidth: 150,
      oversizedBehavior: "truncate",
    })
    legend.data.setAll(series.dataItems);

    // Adds option to export data as an image or data
    // https://www.amcharts.com/docs/v5/concepts/exporting/
    am5exporting.Exporting.new(root, {
      menu: am5exporting.ExportingMenu.new(root, {}),
      dataSource: resultSet.chartPivot(),
      numericFields: resultSet.seriesNames(),
      XLSXOptions: {
        disabled: false,
      },
    });

    // Play initial series animation
    // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  
  }, [resultSet, chartSize, id]);
  
  return (
    <div id={id} style={{ width: "100%", height: 800 }}></div>
  );
}

export default PieChartAM;
